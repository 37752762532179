const reportsEnv = {
  distanceInDay: {
    durationLimit: 31,
    assetLimit: 100,
    apiVer: 'v1.3',
    viewPath: 'distance_day_v5',
    pdfPath: 'distance_pdf_v5',
    excelPath: 'distance_excel_v5',
  },
  distanceInDayA: {
    durationLimit: 31,
    assetLimit: 20,
    apiVer: 'v1',
    viewPath: 'distance_day_wise',
    pdfPath: 'distance_day_wise_pdf',
    excelPath: 'distance_day_wise_excel',
    organizations: ['550f028431414940f6b2e9a8', '57871d6e3141497754156eda', '594652751d41c81a0335e383'],
  },
  distanceInDayB: {
    durationLimit: 1,
    assetLimit: 400,
    apiVer: 'v1',
    viewPath: 'distance_day_wise',
    pdfPath: 'distance_day_wise_pdf',
    excelPath: 'distance_day_wise_excel',
    organizations: ['55519c46421aa9375dc7507e'],
  },
  distanceInTime: {
    durationLimit: 3,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'distance_time_wise',
    pdfPath: 'distance_time_wise_pdf',
    excelPath: 'distance_time_wise_excel',
  },
  distanceInTimeA: {
    durationLimit: 7,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'distance_time_wise',
    pdfPath: 'distance_time_wise_pdf',
    excelPath: 'distance_time_wise_excel',
    organizations: ['5ab394671d41c808a952f9b4'],
  },
  speed: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'speed',
    pdfPath: 'speed_pdf',
    excelPath: 'speed_excel_v3',
  },
  location: {
    durationLimit: 1,
    assetLimit: 10,
    apiVer: 'v1.2',
    viewPath: 'combined_location',
    pdfPath: 'combined_location_pdf',
    excelPath: 'combined_location_excel',
  },
  locationA: {
    durationLimit: 31,
    assetLimit: 10,
    interval: 5,
    apiVer: 'v1.2',
    excelPath: 'combined_location_excel',
    organizations: ['594652751d41c81a0335e383'],
  },
  locationLive: {
    apiVer: 'v1',
    viewPath: 'location_live',
    pdfPath: 'location_live_pdf',
    excelPath: 'location_live_excel',
  },
  summary: {
    durationLimit: 3,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'summary',
    pdfPath: 'summary_pdf',
    excelPath: 'summary_excel',
  },
  summaryA: {
    durationLimit: 3,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'summary_a',
    pdfPath: 'summary_a_pdf',
    excelPath: 'summary_a_excel',
    organizations: ['65684671faf1036c00d77939'],
  },
  geofence: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'geofence_violation',
    pdfPath: 'geofence_violation_pdf',
    excelPath: 'geofence_violation_excel',
  },
  ignition: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'ignition',
    pdfPath: 'ignition_pdf',
    excelPath: 'ignition_excel',
    // not included
    organizations: [
      '5c6279151d41c80d65ca6602',
      '5e01df391d41c8399c605f56',
      '5e1dc0e51d41c8396cabe917',
    ],
    withLocationDurationLimit: 3,
    withLocationOrganizations: ['5e34fda91d41c8396eabeb03'],
  },
  halt: {
    durationLimit: 7,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'ignition_halt',
    pdfPath: 'ignition_halt_pdf',
    excelPath: 'ignition_halt_excel',
    organizations: [
      '5da7e4471d41c83e0c1f5557',
      '5e34fda91d41c8396eabeb03',
      '54f2cb4b421aa9493557fc09',
    ],
    withLocationDurationLimit: 3,
    withLocationOrganizations: ['5e34fda91d41c8396eabeb03'],
  },
  haltA: {
    durationLimit: 1,
    assetLimit: 300,
    apiVer: 'v1',
    viewPath: 'ignition_halt',
    pdfPath: 'ignition_halt_pdf',
    excelPath: 'ignition_halt_excel',
    organizations: ['5ab394671d41c808a952f9b4'],
  },
  ignitionNew: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'ignition_new',
    excelPath: 'ignition_new_excel',
    organizations: ['5e01df391d41c8399c605f56', '5e1dc0e51d41c8396cabe917'],
  },
  fuel: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'fuel',
    pdfPath: 'fuel_pdf',
    excelPath: 'fuel_excel',
    organizations: ['5cbd838e1d41c80835c137b1'],
  },
  fuelNew: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'x3/fuel',
    pdfPath: 'x3/fuel/pdf',
    excelPath: 'x3/fuel/excel',
    organizations: ['5cbd838e1d41c80835c137b1'],
  },
  adc: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'adc_report',
    excelPath: 'adc_report_excel',
    organizations: ['5579229631414928ae120583', '5e646e731d41c8396eabec03'],
  },
  temperature: {
    durationLimit: 3,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'new_temp',
    excelPath: 'new_temp_excel',
    organizations: [
      '56ab7fee31414940ecd1f58a',
      '5d7f34ac1d41c819e480f7bf',
      '5634fa61421aa95a9fac17c4',
      '66e2a431faf103d2b7178958',
      '5cd2996c1d41c8185d548b3f',
      '638ef86f0b77d935db028eb0',
    ],
  },
  temperatureA: {
    durationLimit: 3,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'digital_input_with_temp',
    excelPath: 'new_temp_excel',
    organizations: [
      '5d7f34ac1d41c819e480f7bf',
      '5634fa61421aa95a9fac17c4',
      '66e2a431faf103d2b7178958',
      '5cd2996c1d41c8185d548b3f'	    
    ],
  },
  digitalInput: {
    durationLimit: 3,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'digital_input',
    excelPath: 'digital_input_excel',
    organizations: [
      '5634fa61421aa95a9fac17c4',
      '66e2a431faf103d2b7178958',
      '5cd2996c1d41c8185d548b3f'
    ],
  },
  obd: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'obd',
    pdfPath: 'obd_pdf',
    excelPath: 'obd_excel',
  },
  tpms: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'tpms',
    excelPath: 'tpms_excel',
  },
  distanceA: {
    durationLimit: 31,
    apiVer: 'v1.2',
    excelPath: 'distance_a_excel',
    organizations: [
      '54f2cb4b421aa9493557fc09',
      '5bc6dedd1d41c87634325760',
      '5f212e1f1d41c8550c0b1692',
      '5805e9a11d41c80f35b86faf',
      '6087b3971d41c805d285a0dc',
      '5ab394671d41c808a952f9b4',
      '552b63ef421aa94f2c8ef66e',
      '5c6b988d1d41c82d2df414b5',
      '59ccbc8a1d41c83c9697dc33',
      // '660e2797faf103f4ae9b9c8d',
      // '60af53551d41c805d285a182',
      // '56ab7fee31414940ecd1f58a',
      // '5add988b1d41c827496afa81',
      // '54ab5aacfd0d852a313003a0',
      // '5e520b1c1d41c8396cabeb91',
      // '6431047b0b77d9736270689e',
    ],
  },
  distanceB: {
    durationLimit: 31,
    apiVer: 'v1.2',
    excelPath: 'distance_b_excel',
    organizations: ['55519c46421aa9375dc7507e'],
  },
  distanceC: {
    durationLimit: 31,
    apiVer: 'v1.2',
    excelPath: 'distance_c_excel',
    organizations: ['5efd6d101d41c855060b1632'],
  },
  ignitionA: {
    durationLimit: 31,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'yeti_ignition',
    excelPath: 'yeti_ignition_excel',
    organizations: ['5c6279151d41c80d65ca6602'],
  },
  locationLiveA: {
    excelPath: 'location_live_a_excel',
    apiVer: 'v1.2',
    organizations: [],
  },
  locationLiveB: {
    durationLimit: 31,
    apiVer: 'v1.2',
    viewPath: 'location_live_b_device_status',
    excelPath: 'location_live_b_excel',
    organizations: [
      '5a8980b01d41c873277771d1',
      '5abcca6a1d41c85ce9da4f80',
      '64f57357faf1032f1f8a98a3',
      '6020bcbf1d41c84da5bcb6a3',
      '5bc6dedd1d41c87634325760',
    ],
  },
  locationLiveC: {
    excelPath: 'live_location_c_excel',
    apiVer: 'v1.2',
    organizations: ['5efd6d101d41c855060b1632'],
  },
  locationLiveD: {
    apiVer: 'v1.2',
    excelPath: 'location_live_b_excel',
    organizations: ['60af53551d41c805d285a182', '61fa02b01d41c863a322b73e'],
  },
  rawData: {
    durationLimit: 31,
    assetLimit: 1,
    apiVer: 'v1',
    viewPath: 'raw',
    pdfPath: 'raw_pdf',
    excelPath: 'raw_excel',
  },
  rawFuel: {
    durationLimit: 31,
    assetLimit: 1,
    apiVer: 'v1',
    viewPath: 'fuel_raw',
    pdfPath: 'fuel_raw_pdf',
    excelPath: 'fuel_raw_excel',
  },
  rawTemperature: {
    durationLimit: 1,
    assetLimit: 10,
    apiVer: 'v1',
    viewPath: 'raw_temp_x1',
    excelPath: 'raw_temp_x1_excel',
  },
};

export default reportsEnv;

import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { REGULAR_PATH } from '../../env';
import { Segment, Table, Button, ButtonGroup } from 'semantic-ui-react';
import * as ExcelJS from 'exceljs/dist/exceljs.js';

const accessToken = () => window.localStorage.getItem('user');

export default class Analytics extends Component {
  state = {
    liveToday: '',
    moving: '',
    stopped: '',
    needToCheck: '',
    updatedOn: '',
    tableData: [],
    tableTitle: '',
  };

  componentDidMount() {
    axios({
      method: 'GET',
      url: `${REGULAR_PATH}/assets/list?access_token=${accessToken()}`,
    })
      .then(({ data }) => {
        const assets = data.map((item) => item._id.$oid);
        axios
          .post(
            `${REGULAR_PATH}/assets/dashboard_web?access_token=${accessToken()}`,
            JSON.stringify({ assets }),
          )
          .then(({ data }) => {
            this.setState({
              liveToday: data.live_today,
              moving: data.moving,
              stopped: data.stopped,
              needToCheck: data.need_to_check,
              updatedOn: data.updated_on,
              tableData: data.live_today.assets,
              tableTitle: 'Live Today',
            });
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }

  handleTableData = (data) => {
    const { liveToday, moving, stopped, needToCheck } = this.state;
    switch (data) {
      case 'liveToday':
        this.setState({ tableData: liveToday.assets });
        this.setState({ tableTitle: 'Live Today' });
        break;
      case 'moving':
        this.setState({ tableData: moving.assets });
        this.setState({ tableTitle: 'Moving' });
        break;
      case 'stopped':
        this.setState({ tableData: stopped.assets });
        this.setState({ tableTitle: 'Stopped' });
        break;
      case 'needToCheck':
        this.setState({ tableData: needToCheck.assets });
        this.setState({ tableTitle: 'Need To Check' });
        break;
      default:
        this.setState({ tableData: [] });
        this.setState({ tableTitle: '' });
    }
  };

  downloadExcel = () => {
    const { tableData, tableTitle } = this.state;
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Finder GPS Tracking';
    workbook.created = new Date();

    workbook.addWorksheet('Dashboard Report');

    const worksheet = workbook.getWorksheet('Dashboard Report');

    worksheet.addRow(['Finder GPS Tracking']);
    worksheet.addRow();
    worksheet.addRow([`${tableTitle} : ${tableData.length}`]);
    worksheet.addRow([
      `Generated on ${moment().format('MMMM Do, YYYY, hh:mm:ss A')}`,
    ]);
    worksheet.addRow([`Generated By: ${window.localStorage.getItem('email')}`]);

    worksheet.addRow();

    worksheet.addRow();

    worksheet.columns = [
      { width: 10 },
      { width: 25 },
      { width: 25 },
      { width: 10 },
      { width: 40 },
    ];

    worksheet.addRow(['No', 'Asset Name', 'Time', 'Speed', 'Nearest Landmark']);

    tableData.forEach(({ name, speed, nearest_landmark, time }, index) =>
      worksheet.addRow([
        index + 1,
        name,
        time ? moment(time).utc(true).local().format('DD/MM/YY, H:mm A') : '-',
        speed,
        nearest_landmark,
      ]),
    );

    // Align the first column (No) to the left
    worksheet.eachRow((row, rowNumber) => {
      const cell = row.getCell(1);
      cell.alignment = { vertical: 'middle', horizontal: 'left' };
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/octet-binary',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Dashboard_${moment().format(
        'MMMM Do, YYYY, hh:mm:ss A',
      )}.xlsx`;
      a.click();
      a.remove();
    });
  };

  render() {
    const { liveToday, moving, stopped, needToCheck, tableData, tableTitle } =
      this.state;

    if (
      !['6020bcbf1d41c84da5bcb6a3'].includes(
        window.localStorage.getItem('organization'),
      )
    ) {
      return (
        <h3 style={{ textAlign: 'center' }}>
          You are not eligible to browse this link.
        </h3>
      );
    }

    return (
      <Segment>
        <div>
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup>
              <Button
                color='blue'
                onClick={() => this.handleTableData('liveToday')}
              >
                <span style={{ fontSize: 20 }}>{liveToday.count}</span>
                <div style={{ marginTop: 5 }}>Live Today </div>
              </Button>
              <Button
                color='green'
                onClick={() => this.handleTableData('moving')}
              >
                <span style={{ fontSize: 20 }}>{moving.count}</span>
                <div style={{ marginTop: 5 }}>Moving </div>
              </Button>
              <Button
                color='red'
                onClick={() => this.handleTableData('stopped')}
              >
                <span style={{ fontSize: 20 }}>{stopped.count}</span>
                <div style={{ marginTop: 5 }}>Stopped </div>
              </Button>
              <Button
                color='grey'
                onClick={() => this.handleTableData('needToCheck')}
              >
                <span style={{ fontSize: 20 }}>{needToCheck.count}</span>
                <div style={{ marginTop: 5 }}>Need To Check </div>
              </Button>
            </ButtonGroup>
          </div>
          {tableData.length > 0 ? (
            <Segment>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>{tableTitle}</h3>
                <Button color='teal' onClick={this.downloadExcel}>
                  Download
                </Button>
              </div>

              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>No</Table.HeaderCell>
                    <Table.HeaderCell>Asset Name</Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                    <Table.HeaderCell>Speed (km/h)</Table.HeaderCell>
                    <Table.HeaderCell>Nearest Landmark</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tableData &&
                    tableData.map(
                      (
                        { asset_id, name, speed, nearest_landmark, time },
                        index,
                      ) => (
                        <Table.Row key={asset_id}>
                          <Table.Cell>{index + 1}</Table.Cell>
                          <Table.Cell>{name}</Table.Cell>
                          <Table.Cell>
                            {time
                              ? moment(time).format('DD/MM/YY, hh:mm A')
                              : '-'}
                          </Table.Cell>
                          <Table.Cell>{speed}</Table.Cell>
                          <Table.Cell>{nearest_landmark}</Table.Cell>
                        </Table.Row>
                      ),
                    )}
                </Table.Body>
              </Table>
            </Segment>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <p style={{ fontSize: 22, marginTop: 25 }}>No data available</p>{' '}
            </div>
          )}
        </div>
      </Segment>
    );
  }
}
